import React, { useState } from 'react';
import { VideoCameraOutlined } from '@ant-design/icons';
import { Button, Card, Collapse, Divider, Drawer, Modal, Popover, Statistic, Timeline, } from 'antd';
import dayjs from 'dayjs';
import Cameras from '@/pages/Map/Cameras';
import { getColors, getListIcons, getMarkIcons } from '@/pages/Map/mapUtils';
import { dateTimeFormat, timeFormat } from '@/utils/constants';
function OfficeCard(_a) {
    var office = _a.office, checkOffice = _a.checkOffice, timePoints = _a.timePoints, fieldName = _a.fieldName;
    var _b = useState(false), openDrawer = _b[0], setOpenDrawer = _b[1];
    var _c = useState(false), openCameraModal = _c[0], setOpenCameraModal = _c[1];
    var waitTime = dayjs.utc(office.avg_wait_time * 1000).format(timeFormat);
    var procTime = dayjs.utc(office.avg_proc_time * 1000).format(timeFormat);
    var comments = office.Office.Handlings.sort(function (a, b) { return (dayjs(a.createdAt).isAfter(b.createdAt) ? -1 : 1); });
    var info = office.Office.info;
    var toggleDrawer = function () {
        setOpenDrawer(function (prevState) { return !prevState; });
    };
    var renderMessage = function (comment) {
        if (comment === undefined) {
            return 'Нет предыдущих значений';
        }
        if (comment.includes('http')) {
            return <a href={comment} target="_blank" rel="noreferrer">{comment}</a>;
        }
        return <p className="office__card__statistic__text">{comment}</p>;
    };
    var renderCard = function () {
        var _a, _b, _c;
        switch (office.sync_status) {
            case 1:
                return (<>
            <Statistic title="Время ожидания" value={waitTime} valueStyle={{ color: getColors(office.avg_wait_time, timePoints.wait) }}/>
            <Statistic title="Время обслуживания" value={procTime} valueStyle={{ color: getColors(office.avg_proc_time, timePoints.proc) }}/>
            <Collapse ghost bordered={false} expandIconPosition="end">
              <Collapse.Panel key={office.office_id} header="Подробнее">
                <Popover zIndex={2} placement="topLeft" content={comments.length > 0 && (<div>
                      <p>
                        Предыдущее значение:
                        {' '}
                        <span style={{ fontSize: '16px', color: '#fc4c02' }}>{renderMessage((_a = comments[1]) === null || _a === void 0 ? void 0 : _a.comment)}</span>
                      </p>
                      <p>
                        Инициатор изменения:
                        {' '}
                        <span style={{ fontSize: '16px', color: '#fc4c02' }}>{((_c = (_b = comments[1]) === null || _b === void 0 ? void 0 : _b.Manager) === null || _c === void 0 ? void 0 : _c.fullname) || ''}</span>
                      </p>
                      <Button onClick={toggleDrawer} type="link">Подробнее</Button>
                    </div>)}>
                  <div className="office__card__statistic__box">
                    <p className="office__card__statistic__title">{fieldName}</p>
                    {comments.length > 0 ? renderMessage(comments[0].comment) : <p className="office__card__statistic__text">Нет записей</p>}
                  </div>
                </Popover>
                {info && info.suvc && (<>
                    <Divider style={{ margin: '5px 0' }}/>
                    <Statistic title="Общее кол-во обращений" value={info.suvc.all || 0}/>
                    <Divider style={{ margin: '5px 0' }}/>
                    <Statistic title="Кол-во просроченных обращений" value={info.suvc.overdue || 0}/>
                  </>)}
                <Divider style={{ margin: '5px 0' }}/>
                <Statistic title="Талонов в ожидании" value={office.count_waiting}/>
                <Divider style={{ margin: '5px 0' }}/>
                <Statistic title="Талонов в обслуживании" value={office.count_in_process}/>
                <Divider style={{ margin: '5px 0' }}/>
                <Statistic title="Талонов с превышением времени обслуживания" value={office.count_overserved}/>
                <Divider style={{ margin: '5px 0' }}/>
                <Statistic title="Талонов с превышением времени ожидания" value={office.count_overwaiting}/>
                <Divider style={{ margin: '5px 0' }}/>
                <Statistic title="Активно операторов" value={office.wplaces_busy}/>
                <Divider style={{ margin: '5px 0' }}/>
                <Statistic title="Обслужено клиентов" value={office.count_closed}/>
                <Divider style={{ margin: '5px 0' }}/>
                <Statistic title="Удалено по неявке" value={office.count_deleted}/>
              </Collapse.Panel>
            </Collapse>
          </>);
            case 2:
                return <p>Синхронизация отключена</p>;
            case 3:
                return <p>В офисе отсутствует электронная очередь</p>;
            default:
                return <p>Ошибка синхронизации</p>;
        }
    };
    return (<>
      <Drawer open={openDrawer} onClose={toggleDrawer} destroyOnClose>
        <Timeline mode="left" items={comments.map(function (comment) {
            var _a;
            return ({
                label: dayjs(comment.createdAt).format(dateTimeFormat),
                children: (<div>
                  <b>{renderMessage(comment.comment)}</b>
                  <p>{((_a = comment.Manager) === null || _a === void 0 ? void 0 : _a.fullname) || ''}</p>
                </div>),
            });
        })}/>
      </Drawer>
      <Modal open={openCameraModal} onCancel={function () { return setOpenCameraModal(false); }} destroyOnClose footer={null} width={1400}>
        {info
            && info.cameras
            && <Cameras cameras={info.cameras}/>}
      </Modal>
      <Card hoverable title={(<div className="office__card__header">
            {getListIcons(office.avg_wait_time, office.avg_proc_time, timePoints, office.sync_status !== 1)}
            {info
                && info.cameras
                && info.cameras.length > 0
                && <VideoCameraOutlined onClick={function () { return setOpenCameraModal(true); }}/>}
            <p style={{ whiteSpace: 'break-spaces' }}>{office.office_name}</p>
          </div>)} key={office.office_id} id={String(office.office_id)} onClick={function () { return checkOffice(office); }} extra={getMarkIcons(Number(office.avg_mark))}>
        {renderCard()}
      </Card>
    </>);
}
export default OfficeCard;
